<template>
  <div
    :data-cy="'document-tab-'+document.type"
    class="right-border d-flex flex-grow-1 my-2"
    :class="{ active }"
  >
    <v-btn
      class="document-tab border-radius-left d-flex flex-grow-1 px-3"
      :ripple="!active"
      text
      x-large
      v-on="$listeners"
    >
      <div class="d-flex flex-grow-1">
        <template v-if="document.type">
          <div class="align-self-center mr-3 pa-1">
            <v-icon class="document-icon">{{$icon(`i.${toPascalCase(document.type)}`)}}</v-icon>
          </div>
          <div class="d-flex flex-column align-start">
            <span
              data-cy="count"
              class="counter mr-3"
            >{{document.count || 0}}</span>
            <span class="label mt-1">{{document.name}}</span>
          </div>
          <v-spacer />
          <v-menu
            data-cy="more-documents-button"
            v-if="hasLinkedDocuments"
            v-model="showLinkedDocuments"
            offset-x
            nudge-right=4
            origin="center center"
            transition="scale-transition"
            eager
          >
            <template v-slot:activator="{ on }">
              <v-icon
                class="align-self-center"
                v-on="on"
                :class="{open: showLinkedDocuments}"
              >{{$icon('i.ChevronDown')}}</v-icon>
            </template>
            <v-sheet class="d-flex flex-column background-plain px-2">
              <sub-document-tab
                v-for="document in children"
                :key="document.type"
                ref="item"
                :document="document"
                :search="search"
                @click="$emit('select-document', document)"
              />
            </v-sheet>
          </v-menu>
        </template>
        <template v-else>
          <span class="label align-self-center">{{$tc('t.OtherDocuments', document.count)}}</span>
          <v-spacer />
        </template>
        <slot name="append-icon" />
      </div>
    </v-btn>
  </div>
</template>

<script>
import Search from '@/pages/search/controllers'

export default {
  components: {
    SubDocumentTab: () => import('./document-tab.vue')
  },
  props: {
    active: Boolean,
    document: Object,
    hiddenDocument: Boolean,
    search: Search
  },
  computed: {
    hasTotalSubDocumentsCount () {
      return this.document.children.filter(_ => {
        return _.count > 0
      }).length > 0
    },
    children () {
      return this.document.children
    },
    hasLinkedDocuments () {
      return !this.hiddenDocument && this.children?.length > 0 && this.hasTotalSubDocumentsCount
    }
  },
  data () {
    return {
      showLinkedDocuments: false
    }
  }
}
</script>

<style lang="stylus" scoped>
.right-border
  border-right 2px solid transparent !important

.right-border:hover
  border-right 2px solid silver !important

.right-border.active
  border-right 3px solid var(--primary-color) !important

.active>.document-tab
  color var(--primary-color) !important

  &:before
    opacity 0.1

  .document-icon
    transform scale(1.5)

.counter
  font-size 1.3em

.label
  font-size 0.75em
  word-wrap break-word
  text-align right
  text-transform lowercase

  &:first-letter
    text-transform capitalize

&.open
  rotate -90deg
</style>
