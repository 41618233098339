import { render, staticRenderFns } from "./document-tab.vue?vue&type=template&id=45f0e05a&scoped=true&"
import script from "./document-tab.vue?vue&type=script&lang=js&"
export * from "./document-tab.vue?vue&type=script&lang=js&"
import style0 from "./document-tab.vue?vue&type=style&index=0&id=45f0e05a&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45f0e05a",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VIcon,VMenu,VSheet,VSpacer})
